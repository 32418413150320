import Layout from '@/widgets/Layout/ui/Layout';
import { AppHead } from '@/shared/ui/AppHead';
import { ContactUsSection } from '@/widgets/ContactUsSection';
import React from 'react';
import { ProductCards } from '@/widgets/ProductCards';
import LookupForm from '@/entities/Lookup/ui/LookupForm/LookupForm';
import { GetServerSideProps } from 'next';
import { getTestIps } from '@/entities/Lookup/api/v1Test';
import { TestIPs } from '@/entities/Lookup/model/types/lookup';
import OurDataEnrichments from '@/entities/Blocks/OurDataEnrichments';
import WeCoverThreatTypes from '@/entities/Blocks/WeCoverThreatTypes';
import UseCases from '@/entities/Blocks/UseCases';
import { TrustedBy } from '@/widgets/TrustedBy';
import OurTIDataSources from '@/entities/Blocks/OurTIDataSources';
import TryItNow from '@/entities/Blocks/TryItNow';

interface HomePageProps {
    ips: TestIPs;
}

export default function HomePage(props: HomePageProps) {
    const { ips } = props;

    return (
        <Layout>
            <AppHead
                title="FalconSentinel – Stay ahead of cyber threats with our intelligence tools"
                description="Equips your security systems with a comprehensive list of IoCs, including IPs, domain names, and URLs, for automatic threat detection and response. The premium version enhances this with in-depth threat intelligence, similar to our Lookup service. Select from raw or pre-filtered feeds, both tailored for smooth integration within your infrastructure."
            />
            <div className="flex flex-col items-center gap-12 sm:gap-16 px-5 xl:px-0">
                <h1 className="max-w-3xl text-gray-800 bg-clip-text text-center text-4xl font-bold tracking-[-0.02em] md:text-6xl">
                    Stay ahead of cyber threats with our intelligence tools
                </h1>
                <p className="max-w-6xl text-center text-gray-500 md:text-xl">
                    Enhance your business's security with our solutions that
                    deliver real-time intelligence on emerging cyber threats.
                    Choose from our comprehensive range of offerings, including
                    raw data feeds, APIs, and web lookup, all designed for
                    seamless integration with your existing security
                    infrastructure. This ensures rapid threat detection and
                    response, strengthening your business's defenses.
                </p>
                <div className="max-w-3xl w-full h-fit overflow-hidden rounded-xl border border-fs-primary bg-white shadow-md p-4 sm:p-8 sm:pb-6">
                    <LookupForm ips={ips} />
                </div>

                <ProductCards />
                <UseCases />
                <OurDataEnrichments />
                <WeCoverThreatTypes />
                <OurTIDataSources />
                <TrustedBy />
                <TryItNow />
                <ContactUsSection />
            </div>
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async context => {
    const lookupFormProps = await getTestIps();

    return {
        props: {
            ...lookupFormProps
        }
    };
};
